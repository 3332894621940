html {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "BodoniFLF";
  src: url("./assets/fonts/BodoniflfRoman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BodoniFLF";
  src: url("./assets/fonts/BodoniflfItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "BodoniFLF";
  src: url("./assets/fonts/BodoniflfBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BodoniFLF";
  src: url("./assets/fonts/BodoniflfBolditalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Sacramento";
  src: url("./assets/fonts/Sacramento-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CatchyMager";
  src: url("./assets/fonts/CatchyMager-Normal.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Belleza";
  src: url("./assets/fonts/Belleza-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Pour les navigateurs WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Largeur de la scrollbar */
}

::-webkit-scrollbar-track {
  background: #d8d8d8; /* Couleur de fond de la piste de la scrollbar */
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée de la scrollbar */
}

/* Pour Firefox */
::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-track {
  background: #d8d8d8;
  border: none;
}

::-moz-scrollbar-thumb {
  background: #888;
}

/* Pour Internet Explorer et Edge */
::-ms-scrollbar {
  width: 10px;
}

::-ms-scrollbar-track {
  background: #d8d8d8;
  border: none;
}

::-ms-scrollbar-thumb {
  background: #888;
}